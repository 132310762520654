import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Customers = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/list'))} />
      <Route path={`${match.url}/completed`} component={asyncComponent(() => import('./routes/completed'))} />
      <Route path={`${match.url}/new`} component={asyncComponent(() => import('./routes/new'))} />
      <Route path={`${match.url}/progress`} component={asyncComponent(() => import('./routes/progress'))} />
      <Route path={`${match.url}/purolatorList`} component={asyncComponent(() => import('./routes/purolatorList'))} />

      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
    </Switch>
  </div>
);

export default Customers;
